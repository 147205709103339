import * as React from "react"
import { Link, graphql } from "gatsby"
//import Img from "gatsby-image"

import { GatsbyImage } from "gatsby-plugin-image"
import scrollTo from 'gatsby-plugin-smoothscroll'
// import BackgroundSlider from 'gatsby-image-background-slider'

import Layout from "../components/layout"
import Seo from "../components/seo"

import { motion, useViewportScroll, useTransform } from 'framer-motion'

import picTopHyoudai from '../images/TopHyoudai.svg'

export default function IndexPage( {data} ) {
	
	const { scrollYProgress } = useViewportScroll()
	const opacity = useTransform(scrollYProgress, [0, 1], [1, -5]) // マイナスにすることによって減るスピードが速くなる

	return (
  	<Layout>
    	<Seo title="佐々畜産 表紙" description="熊本県菊池市で和王を肥育する佐々畜産のウェブサイトです。" />
		<motion.section id="banner" style={{opacity: opacity, backgroundColor: 'white'}}>
			{/* <div className="titlebox siteTop"> */}
			<div className="titlebox">
				<motion.img
					className="toptitle"
					src={picTopHyoudai}
					initial={{opacity:0}}
					animate={{opacity:1}}
					transition= {{duration:1.5}}
					alt="佐々畜産"
				/>
				<button type="button" className="material-icons btn-next cp_anime08" onClick={() => scrollTo('#main')}>expand_more</button>
			</div>
		</motion.section>

		<div id="main">

			<section className="index-section index" id="kodawari">
				<div className="baseBox">
					<Link to="/kodawari">
						<GatsbyImage className="img" image={data.Kikuchikeikoku.childImageSharp.gatsbyImageData} alt=""/>
					</Link>
				</div>
				<div className="content">
					<div className="centering-container">
						<h2>こだわりの牛飼い</h2>
						<p className="margin-bottom30">新しいものが、有形無形を問わず、物事に妥協せず、とことん追求する</p>
						<Link to="/kodawari" className="button btn-sticky">続く</Link>
					</div>
				</div>
			</section>



			<section className="index-section index">
				<div className="baseBox">
					<Link to="/AI">
						<GatsbyImage className="img" image={data.AI.childImageSharp.gatsbyImageData} alt=""/>
					</Link>
				</div>
				<div className="content">
					<div className="centering-container">
						<h2>AIを用いた畜産</h2>
						<p className="margin-bottom30">各個体の24時間のデータを集積人工知能によるアラート機能がとても重宝しています</p>
						<Link to="/AI" className="button btn-sticky">続く</Link>
					</div>
				</div>
			</section>



			<section className="index-section index">
				<div className="baseBox">
					<Link to="/about">
						<GatsbyImage className="img" image={data.Gaiyou.childImageSharp.gatsbyImageData} alt=""/>
					</Link>
				</div>
				<div className="content">
					<div className="centering-container">
						<h2>会社概要</h2>
						<p className="margin-bottom30">農場の紹介など</p>
						<Link to="/about" className="button btn-sticky">続く</Link>
					</div>
				</div>
			</section>



			<section className="index-section index">
				<div className="baseBox">
					<Link to="/recruit">
						<GatsbyImage className="img" image={data.Bosyuu.childImageSharp.gatsbyImageData} alt=""/>
					</Link>
				</div>
				<div className="content">
					<div className="centering-container">
						<h2>お問い合わせ窓口</h2>
						<p className="margin-bottom30">ご質問、ご相談、スタッフ募集についてなど、各種お問い合わせはこちらからどうぞ。</p>
						<Link to="/recruit" className="button btn-sticky">続く</Link>
					</div>
				</div>
			</section>



			<section className="index-section index">
				<div className="baseBox">
					<a href="https://blog.sassa-chikusan.jp/">
						<GatsbyImage className="img" image={data.Nikki.childImageSharp.gatsbyImageData} alt=""/>
					</a>
				</div>
				<div className="content">
					<div className="centering-container">
						<h2>日々のニュース</h2>
						<p>不定期更新、佐々畜産のニュース（共励会・出荷牛など）</p>
						<a className="button btn-sticky" href="https://blog.sassa-chikusan.jp/">続く</a>
					</div>
				</div>
			</section>



			<section className="index-section index">
				<div className="baseBox">
					<a href="https://column.sassa-chikusan.jp/">
						<GatsbyImage className="img" image={data.Koramu.childImageSharp.gatsbyImageData} alt=""/>
					</a>
				</div>
				<div className="content">
					<div className="centering-container">
						<h2 className="text-center">牛飼い獣医師の豆知識<br/>〜論より証拠〜</h2>
						<p>コラム</p>
						<a className="button btn-sticky" href="https://column.sassa-chikusan.jp/">続く</a>
					</div>
				</div>
			</section>

		</div>
  	</Layout>
	)
}

export const query = graphql `
	query {
		Kikuchikeikoku:file(relativePath: {eq: "kikuchikeikoku.jpg"}) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			  }
		}
		AI:file(relativePath: {eq: "index_AI.jpg"}) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			  }
		}
		Gaiyou:file(relativePath: {eq: "gyuusya.jpg"}) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			  }
		}
		Bosyuu:file(relativePath: {eq: "bosyuu.jpg"}) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			  }
		}
		Nikki:file(relativePath: {eq: "diary.jpg"}) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			  }
		}
		Koramu:file(relativePath: {eq: "koramu.jpg"}) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			  }
		}
	}`